// @flow
import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection58: JSSThemeDefaultAccountSectionType = themeVars => {
  const {
    colors: {
      colorTypes: { background3 },
    },
  } = themeVars;
  return {
    OPMProvider: {
      providerRoot: {
        background: background3,
        border: '1px solid #223553',
        boxShadow: 'none',
        maxWidth: 170,
      },
      logoImg: {
        marginBottom: 10,
        opacity: '.9',
      },
    },
    OPMPageComponent: {
      heading: {
        margin: [10, 0, 10, 10],
      },
    },
  };
};
