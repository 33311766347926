// @flow
import { type JSSThemeDefaultSitemapType } from '../JSSThemeDefaultSitemap';

export const JSSThemeSitemap58: JSSThemeDefaultSitemapType = themeVars => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    sitemapTitle: {
      color: white,
    },
  };
};
