// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm58: JSSThemeDefaultAuthFormType = (themeVars) => {
  const {
    margins,
    borderRadius,
    colors: {
      colorTypes: { background5, text2 },
    },
  } = themeVars;
  return {
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.md],
        background: background5,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
      },
    },
    PasswordResetWithEmail: {
      passwordResetDebounce: {
        color: text2,
      },
    },
  };
};
