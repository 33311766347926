// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions58: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { error },
    },
  } = themeVars;
  return {
    SeasonalPromotion: {
      promoHeading: {
        padding: 0,
        position: 'relative',
      },
      buttonRoot: {
        background: 'transparent',
        position: 'absolute',
        top: 70,
      },
      img: {
        objectPosition: 'right',
      },
      root: {
        padding: ['18px', margins.md],
      },
    },
    PromotionsComponent: {
      selectedLink: {
        color: `${error} !important`,
        borderBottom: `3px solid ${error} !important`,
        '&:hover': {
          opacity: `1 !important`,
        },
      },
    },
  };
};
