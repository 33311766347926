// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61,
  JSSThemeVariables61,
  getJSSThemePatterns61,
} from './JSSTheme61';
import { GeoblockPage61 } from './GeoblockPage61';

// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop61')).FullDesktop61,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile61')).FullMobile61,
  }),
  JSSTheme: JSSTheme61,
  JSSThemeVariables: JSSThemeVariables61,
  JSSThemePatterns: getJSSThemePatterns61,
  GeoblockPage: GeoblockPage61,
});
