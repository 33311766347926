// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu58: JSSThemeDefaultHeaderMenuType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { white, primary, background3 },
    },
  } = themeVars;
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        color: white,
        fontWeight: 'bold',
        padding: [0, margins.lg],
        '&:hover, &:focus': {
          color: primary,
        },
        '@media screen and (max-width: 1700px)': {
          padding: '0px 7px',
        },
        '@media screen and (max-width: 1600px)': {
          fontSize: 13,
        },
        '@media screen and (max-width: 1300px)': {
          fontSize: 11,
          padding: '0px 5px',
        },
        '@media screen and (max-width: 1060px)': {
          fontSize: 10,
          padding: '0px 4px',
        },
      },
      menuItemActive: {
        color: primary,
      },
    },
    HeaderMenuMobile: {
      mobileMenu: {
        background: background3,
        flexWrap: 'wrap',
      },
      menuItem: {
        borderBottom: `3px solid ${background3}`,
        '&:hover, &:focus, &:active': {
          color: white,
        },
      },
      // menuItemActive: {
      //   background: text3,
      //   borderBottom: `3px solid ${primary}`,
      // },
    },
  };
};
