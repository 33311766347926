// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader58: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background1 },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: background1,
      },
      authContentWrap: {
        justifyContent: 'normal',
        padding: [margins.xs, 0, 0],
        '@media screen and (max-width: 1220px)': {
          marginRight: 10,
        },
      },
      extraUserElementsWrapper: {
        margin: [margins.xs, 0, margins.sm],
      },
    },
    LogoBrand: {
      logoWrapper: {
        width: 230,
        padding: '7px 10px 10px',
        '@media screen and (max-width: 1470px)': {
          width: 180,
          flexShrink: 0,
        },
        '@media screen and (max-width: 1200px)': {
          width: 150,
        },
      },
      logo: {
        maxWidth: 160,
      },
    },
  };
};
