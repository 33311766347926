// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets58: JSSThemeDefaultMyBetsType = themeVars => {
  const {
    colors: {
      colorCombinations: { whiteBg1, whiteNeutral1, text2Bg3 },
      colorTypes: { white, neutral1 },
    },
  } = themeVars;

  return {
    ConfirmationBox: {
      text: {
        color: white,
        fontWeight: 'normal',
      },
      confirmation: {
        background: neutral1,
      },
      loading: whiteNeutral1,
    },
    CashOut: {
      success: whiteNeutral1,
    },
    MyBets: {
      content: {
        ...whiteBg1,
      },
      myBetsTabsItem: {
        ...text2Bg3,
        '&:hover, &.active': whiteNeutral1,
      },
    },
  };
};
